@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@500&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
    font-family: Poppins;
    src: local('Poppins'), url(./Styles/font/Poppins/Poppins-Medium.ttf) format('truetype');
} */
@font-face {
    font-family: 'Poppins';
    font-weight: 100;
    src: url('./Styles/font/Poppins/Poppins-Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 200;
    src: url('./Styles/font/Poppins/Poppins-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 300;
    src: url('./Styles/font/Poppins/Poppins-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 400;
    src: url('./Styles/font/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 500;
    src: url('./Styles/font/Poppins/Poppins-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 600;
    src: url('./Styles/font/Poppins/Poppins-SemiBold.ttf') format('truetype');

}

@font-face {
    font-family: 'Poppins';
    font-weight: 700;
    src: url('./Styles/font/Poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 800;
    src: url('./Styles/font/Poppins/Poppins-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 900;
    src: url('./Styles/font/Poppins/Poppins-Black.ttf') format('truetype');
}


body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
    /* font-family: 'Fira Code', monospace !important; */
    font-family: Poppins;
    /* font-style: normal; */
    /* line-height: 60px; */
    color: black;
    background-color: white;
}

/* code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
} */
