.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    font-size: 18px;
    background: transparent;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    /* -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center; */
    /* align-items: center; */
}

.swiper-slide img {
    /* display: block; */
    /* width: 100%;
    height: 100%;
    object-fit: cover !important; */
}

.swiper-pagination {
    /* border: 1px solid red; */
}

.swiper-pagination-bullet-active {
    width: 30px !important;
    height: 10px !important;
    border-radius: 10px !important;
    background: #394978 !important;
    transition: ease-in-out .25s;
}

.swiper-button-disabled {
    display: none !important;
}

.swiper-button-next {
    /* position: absolute !important; */
    /* right: -20px !important; */
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -30px !important;
}

.swiper-slide-active {
    width: 100%;
    height: 100%;
    object-fit: cover !important;
}
